html,
body {
  margin: 0px;
  height: 100%;
}
.gallery {
    background-color: #4056f4;
    height: 100%;
    margin: auto;
    width: 100%;
    overflow: hidden;
}

.gallery .container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 100px;

    background-image: url("./images/other-circle.svg");
    background-position:  105% 70%;
    background-repeat: no-repeat;
    background-size: 400px 400px;
}

.carosoul {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;  
    gap: 70px;
    width: 100%;



}


.carosoul img {
    max-width: 500px;
    max-height: 500px ;
   }

   .carosoul .buttons {
    max-width: 200px;
    max-height: 100px;
   }

   .gallery .footer {
    background-color: black;
    text-align: center;
    width: 100%;
    justify-self: flex-end;
    max-height: 400px;
    padding: 20px;

   }

    .footer img {
        max-width: 300px;
        min-height: 100px;
        margin-bottom: 5%;

   }

   @media screen and (max-width:940px) {
    .carosoul {
        gap: 10px;
        margin-bottom: 20px;
    }

    .carosoul img {
        max-width: 250px;
        max-height: 250px ;
        border-radius: 10px;
       }
    
       .carosoul .buttons {
        max-width: 50px;
        max-height: 100px;
       }

       
    .gallery .container{
        background-position:  108% 70%;
        background-size: 200px 200px;

    }

    .gallery .footer {
        max-height: 200px;
        padding: 10px;
    
       }
    
   }