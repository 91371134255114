html,
body {
  margin: 0px;
  height: 100%;
}
.products {
    background-color: #F96900;
    height: 100%;
    margin: auto;
    width: 100%;
    overflow-y: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.products::-webkit-scrollbar {
    display: none;
  }

.products .container {
   height: 100%;
   display: flex;
   flex-direction: column;
   padding: 20px;
}

.products .title h1 {
    text-align: left;
    font-family: 'Neue Metana', sans-serif;
    font-size: 70px;
    font-weight: 600;
    color: #d8cbc7;
    margin-bottom: 0;
}

.products .title {
    padding: 20px;
    margin-left: 40px;
}

.products .title p {
    text-align: left;
    font-family: 'Neue Metana', sans-serif;
    font-size: 20px;
    color: #d8cbc7;
    margin-top: 0;
}

.product-images {
    display: flex;
    flex-direction: row;
    gap: 10px;
    width: 100%;

}

.product-images .product-card-1 {
    background-image: url('./images/back-up-systems.png');
    background-repeat: no-repeat;
    background-size: cover;
    min-width: 359px;
    min-height: 650px;
}

.product-images .product-card-2 {
    background-image: url('./images/solar-systems.png');
    background-repeat: no-repeat;
    background-size: cover;
    min-width: 359px;
    min-height: 650px;
}

.product-images .product-card-3 {
    background-image: url('./images/complete-systems.png');
    background-repeat: no-repeat;
    background-size: cover;
    min-width: 359px;
    min-height: 650px;
}

.product-images .product-card-4 {
    background-image: url('./images/solar-geysers.png');
    background-repeat: no-repeat;
    background-size: cover;
    min-width: 359px;
    min-height: 650px;
}

.product-images h3 {
    text-align: left;
    font-family: 'Neue Metana', sans-serif;
    font-size: 35px;
    font-weight: 400;
    color: black;
}

.product-images p {
    text-align: left;
    font-family: 'Neue Metana', sans-serif;
    font-size: 15px;
    color: black;
}


.closer {
    margin-top: 40px;
   width: 100%; 
}

.closer p {
    text-align: center;
    font-family: 'Neue Metana', sans-serif;
    font-size: 20px;
    color: #d8cbc7;
}


@media screen and (max-width:940px) {
    .product-images {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .products .title {
        padding: 5px;
        margin-left: 0;
    }

    .products .title h1 {
        font-size: 55px;
    }
    

}