.header {
    position: fixed;
    height: 90px;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    transition: .3s ease-in;
    overflow: hidden;
    background-color: transparent;
}

.productsState {
    position: fixed;
    height: 90px;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    transition: .3s ease-in;
    overflow: hidden;
    background-color: #f96900;
}

.productsState .nav-item a:hover {
    border: 2px solid #d8cbc7;
    background-color: #f96900;
    color: #d8cbc7;
    padding: 2px 10px 2px 10px;
    border-radius: 20px;

}

.aboutState {
    position: fixed;
    height: 90px;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    transition: .3s ease-in;
    overflow: hidden;
    background-color: #4056f4;
}

.aboutState .nav-menu a {
    color: #d8cbc7;
    text-decoration: none;
    font-size: 25px;
}

.aboutState .nav-item a:hover {
    border: 2px solid #4056f4;
    background-color: #d8cbc7;
    color: #4056f4;
    padding: 2px 10px 2px 10px;
    border-radius: 20px;

}

.servicesState {
    position: fixed;
    height: 90px;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    transition: .3s ease-in;
    overflow: hidden;
    background-color: #33673b;
}

.servicesState .nav-menu a {
    color: #f96900;
    text-decoration: none;
    font-size: 25px;
}

.servicesState .nav-item a:hover {
    border: 2px solid #f96900;
    background-color: #33673b;
    color: #f96900;
    padding: 2px 10px 2px 10px;
    border-radius: 20px;

}

.contactUsState {
    position: fixed;
    height: 90px;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    transition: .3s ease-in;
    overflow: hidden;
    background-color: #d8cbc7;
}


.contactUsState .nav-menu a {
    color: black;
    text-decoration: none;
    font-size: 25px;
}

.contactUsState .nav-item a:hover {
    border: 2px solid black;
    background-color: #d8cbc7;
    color: black;
    padding: 2px 10px 2px 10px;
    border-radius: 20px;

}



.navbar {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    max-width: 100%;
    margin: auto;
    height: 100%;
    padding: 0 1rem;
}

.nav-menu a {
    color: #d8cbc7;
    text-decoration: none;
    font-size: 25px;
}


 .nav-menu {
    display: flex;
}

 .nav-item {
    padding: 1rem;
    font-weight: 300;

    font-family: 'Neue Metana', sans-serif;
}

.header .nav-item a:hover {
    border: 2px solid #4056f4;
    background-color: #d8cbc7;
    color: #4056f4;
    padding: 2px 10px 2px 10px;
    border-radius: 20px;

}


 .nav-item::marker {
    color: transparent;
}



@media screen and (max-width:940px) {
    .header {
        display: none;
    }

    .aboutState {
        display: none;
    }

    .contactUsState {
        display: none;
    }

    .productsState {
        display: none;
    }

    .servicesState {
        display: none;
    }


}