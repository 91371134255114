html,
body {
  margin: 0px;
  height: 100%;
}
.about {
    background-color: #4056f4;
    height: 100%;
    margin: auto;
    width: 100%;
    overflow-y: scroll;

    -ms-overflow-style: none;  
    scrollbar-width: none;  
}

.about::-webkit-scrollbar {
    display: none;
  }
  


.about .container {
    padding-top: 8%;
    overflow-x: hidden;
}

.about .bottom-image {
    height: 22%;
    width: 35%;
    background-image: url("./images/bottom-circle.svg");
    background-repeat: no-repeat;
    background-position-x: -200px;
    background-size: cover;
}

.about .continue-image {
    width: 355px;
    min-height: 250px;
    background-image: url("./images/director.jpg");
    background-repeat: no-repeat;
    background-size: cover;
}


.aboutus {
    background-color: #d8cbc7;
    padding: 20px;
    border-radius: 14%;
    width: 100%;
    margin-top: -52px;
}

.mantana-bold{
    text-align: center;
    font-family: 'Neue Metana', sans-serif;
    font-weight: 700;
    font-size: 20px;
}

.mantana-underline{
    text-align: center;
    margin-top: 40px;
    font-family: 'Neue Metana', sans-serif;
    text-decoration: underline;
    font-size: 20px;

}

.large-paragraph {
    text-align: center;
    margin-top: 40px;
    font-family: 'Neue Metana', sans-serif;
    font-size: 15px;
}

.large-paragraph span {
    text-align: center;
    margin-top: 40px;
    font-family: 'Neue Metana', sans-serif;
    font-size: 15px;
    font-weight: 600;
}

.large-paragraph:last-child {
    margin-bottom: 100px;
}

.about .workers {
    background-image: url("./images/building-background.svg");
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    padding-bottom: 70px;

}

.about .pop-up {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: rgba(255,255,255, 0.5);
    border-radius: 60px;
    width: 60%;
    padding: 50px;
    margin-top: 50px;
}

.about .director {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    gap: 50px;
    align-items: flex-start;
}

.about .our-team {
    margin-top: 100px;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    gap: 50px;
    align-items: flex-start;
}

.director .image {
    width: 355px;
    min-height: 200px;
    background-image: url("./images/director.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    border-top-left-radius: 60px;
}

.our-team .image {
    width: 355px;
    min-height: 250px;
    background-image: url("./images/team.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    border-bottom-right-radius: 60px;
}

.workers .text-box {
    min-width: 300px;
}

.about .text-box h3 {
    margin-top: 0;
    text-align: left;
    font-family: 'Neue Metana', sans-serif;
    font-size: 20px;
    font-weight: 600;
}

.about .text-box p {
    text-align: left;
    font-family: 'Neue Metana', sans-serif;
    font-size: 15px;
}

@media screen and (max-width:940px) {
    .about .container {
        padding-top: 100px;
        overflow-x: hidden;
    }

    .aboutus {

        border-radius: 11%;
    
    }
    .about .text-box p {
        font-size: 15px;
    }

    .workers .text-box {
        min-width: 10px;
    }

    .about .workers .text-box {
        width: 100%;
    }

    .about .director .text-box {
     width: 100%;   
    }

    .about .director {
        flex-direction: column;
    }

    .about .our-team {
        flex-direction: column;
    }

    .director .image {
        width: 100%;
        min-height: 200px;
    
    }

    .our-team .image {
        width: 100%;
        min-height: 200px;
    
    }
    
   
   }