html,
body {
  margin: 0px;
  min-height: 100%;
}
.home {
    background-color: #4056f4;
    height: 100%;
    margin: auto;
    width: 100%;
}

.home .mobile-container {
    display: none;
}

.home .container {
    margin: auto;
    height: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
}

.first-grid {
height: 100%;
display: flex;
flex-direction: column;
justify-content: flex-end;

}


.home .text-box {
width: 100%;
height: 100%;
background-image: url("./images/oga-pictorial-logo.svg");
background-repeat: no-repeat;
background-position: top left;
background-size: 10%;
margin-top: 20px;
margin-left: 15px;
}

.text {
    margin-left: 15px;
    position: absolute;
    bottom: 335px;
}

.text h1 {
    font-size: 40px;
    color: #d8cbc7;
    font-family: 'Neue Metana', sans-serif;
    font-weight: 900;
}

.text p {
    font-size: 25px;
    color: #d8cbc7;
    font-family: 'Neue Metana', sans-serif;
}

.text p span {
    border: 2px solid #d8cbc7;
    padding: 2px 10px 2px 10px;
    border-radius: 20px;
}

.home .image-box {
    height: 60%;
    width: 70%;
    background-image: url("./images/top-circle.svg");
    background-repeat: no-repeat;
    /* background-position: bottom left; */
    background-position-x: -200px;
    background-size: cover;
    /* border-top-right-radius: 100%; */

    display: flex;
    flex-direction: column-reverse;

}

.image-box img {
    max-height: 128px;
    max-width: 234px;
    margin-left: 15px;
    margin-left: 15px;
}

.second-grid {
    background-image: url("./images/orange-circle.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: right;
}

@media screen and (max-width:940px) {
 .home .container{
    display: none;
 }

 .home .mobile-container {
    background-image: url("./images/orange-circle.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: right; 
    height: 100%; 

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-x: none;
 }

 .mobile-container .mobile-text {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 100px;
    padding: 20px;

    /* height: 100%; */
 }


 .mobile-text h1 {
    font-size: 30px;
    color: #d8cbc7;
    font-family: 'Neue Metana', sans-serif;
    font-weight: 900;
}

.mobile-text p {
    font-size: 25px;
    color: #d8cbc7;
    font-family: 'Neue Metana', sans-serif;
}

.mobile-text p span {
    border: 2px solid #d8cbc7;
    padding: 2px 10px 2px 10px;
    border-radius: 20px;
}

.mobile-container .mobile-image {
    display: flex;
    justify-content: baseline;
    align-items: flex-end;
    margin: 10px;
}

.mobile-image img {
    max-width: 360px;
}
}