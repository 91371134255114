.contactUs {
    background-color: #d8cbc7;
    height: 100%;
    margin: auto;
    width: 100%;
    overflow-y: scroll;
    -ms-overflow-style: none;  
    scrollbar-width: none;  
}

.contactUs .container {
   padding: 20px 30px 30px 30px;
    overflow-x: hidden;
}

.contactUs::-webkit-scrollbar {
    display: none;
  }

.container .title {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.left-heading {
    text-align: right;
    font-size: 60px;
    font-family: 'Neue Metana', sans-serif;
    font-weight: 100;
    margin-bottom: 10px;
}

.right-heading {
    text-align: right;
    font-size: 60px;
    font-family: 'Neue Metana', sans-serif;
    font-weight: 100;
    margin-right: 80px;
    margin-top: 0;
}
.underline {
    font-family: 'Neue Metana', sans-serif;
    font-weight: 100;
    font-size: 20px;
    text-decoration: underline;
    text-align: right;
    letter-spacing: 5px;
}

.contact {
    font-family: 'Neue Metana', sans-serif;
    font-weight: 100;
    font-size: 17px;
    text-align: right;  
}

.form {
    display: flex;
    flex-direction: column;
    width: 50%;
}

.label {
    display: flex;
    flex-direction: column;
    font-family: 'Neue Metana', sans-serif;
    font-weight: 100;
    font-size: 17px
}

.form-group {
    display: flex;
    flex-direction: row;
}

.label input {
    background: transparent;
    border: none;
    margin-top: 10px;
    margin-bottom: 15px;
    border-bottom: 1px solid;
    padding-bottom: 10px;
    font-size: 13px;
    font-family: 'Neue Metana', sans-serif;
    font-weight: 100;
}

.label input:focus-visible {
   outline: none;
}

.label textarea {
    background: transparent;
    border: 1px solid black;
    margin-top: 10px;
    margin-bottom: 15px;
    padding: 10px;
    font-size: 13px;
    font-family: 'Neue Metana', sans-serif;
    font-weight: 100;
    resize: none;
}

.form-group .logo {
    background-image: url("./images/oga-pictorial-logo-black.svg");
    width: 100%;
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: 5%;
}

@media screen and (max-width:940px) {
    .left-heading {
        margin-top: 30px;
        text-align: left;
        font-size: 50px;
    }
    .right-heading {
        text-align: left;
        font-size: 50px;
    }

    .contact {
        text-align: left;
    }
    .underline {
        text-align: left;
    }
    .form {
        margin-top: 40px;
        width: 100%;
    }
}