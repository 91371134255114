html,
body {
  margin: 0px;
  height: 100%;

}
.services {
    background-color: #33673B;
    height: 100%;
    margin: auto;
    width: 100%;
    overflow-y: hidden;

    background-repeat: no-repeat;
    background-position-y: 100%;

}

.services .container {
    height: 100%;
    display: flex;
    flex-direction: row;
}

.services .large-image {
    background-image: url("./images/greenery.svg");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;

    height: 100%;
}

.large-image h1 {
    color: #F96900;
    font-size: 60px;
    text-align: left;
    font-family: 'Neue Metana', sans-serif;
    font-weight: 500;
    position: relative;
    left: 47%;
    min-width: 700px;
    margin-top: 120px;
    letter-spacing: 8px;
}

.services .text-box {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px;
}

.services .text-box h3 {
    color: #d8cbc7;
    font-size: 20px;
    font-family: 'Neue Metana', sans-serif;
    max-width: 500px;
    margin-left: 35px;
}

.services .text-box li {
    color: #d8cbc7;
    font-size: 15px;
    font-family: 'Neue Metana', sans-serif;
}


@media screen and (max-width:940px) {
    .services .container {
        flex-direction: column;
        overflow-x: hidden;
    }

    .services .text-box h3 {
        margin-left: 2px;
    }

    .large-image h1 {
        min-width: 200px;
        left: 2%;
        font-size: 55px;
    }

    .services .large-image {
        overflow-x: hidden;
    }
}